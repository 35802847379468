import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    redirect: '/'
  },
  // Home Page
  {
    path: '/',
    name: 'home',
    component: () => import('../views/site/AboutView.vue')
  },
  // Contact Page
  {
    path: '/contact',
    name: 'Contact-Page',
    component: () => import('../views/site/ContactPage.vue')
  },
  // Contact Page
  {
    path: '/Resume',
    alias: '/CV',
    name: 'Resume/CV-Page',
    component: () => import('../views/site/ResumeCV.vue')
  },
  // Showcase
  { 
    path:'/showcase',
    name:'showcase',
    children: [
      { path: 'maths', name: 'maths', component: () => import( '../views/showcase/Coding/maths.vue')},
    ],
  },
  { 
    path:'/projects',
    name:'Member Services Home',
    children: [
      { path: '', name: 'Main', component: () => import('../views/showcase/Projects.vue')},
      { path: 'GFA', name: 'GFA', component: () => import('../views/showcase/GFA/GFA.vue')},
      { path: 'GFA-L&E',name: 'GFA-L', component: () => import('../views/showcase/GFA/Lighting.vue')},
      { path: 'GFA-G&R',name: 'GFA-G', component: () => import('../views/showcase/GFA/Rigging.vue')},
      { path: 'GFA-C&P',name: 'GFA-C', component: () => import('../views/showcase/GFA/GFA.vue')},
      { path: 'JCHS', name: 'JCHS', component: () => import('../views/showcase/JCHS/JCHS.vue')},
    ],
  },
  {
    path: '/slide',
    name: 'slide',
    component: () => import( '../views/Test/slide.vue')
  },
  {
    path: '/test',
    name: 'slide',
    component: () => import( '../views/Test/testing.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 300,
        behavior: 'smooth',
      }
    } 
    if (savedPosition){
      return savedPosition
    } else {
      return { x:0, y:0}
    }
  },
})
export default router 
