<template>
  <footer id="footer">
      <div class="grid-container footer">
        <div class="love h2-left">
          <h4>Made By,</h4>
          <h2>P. MICHAEL BENDER</h2>
          <nav>
            <SLinks></SLinks>
            <a href="mailto:pmb@pmbender.com"><button>Contact</button></a>
            <!-- <router-link to="/contact"><button>Contact</button></router-link> -->
          </nav>
        </div>
        <div class="ipumon h2-right">
          <!-- <p>Made by a <a target="_blank" href="http://mywork.pmbender.com/">IPUMON Member</a> 2023</p>  -->
          
        </div>
      </div>
    </footer>
</template>
<script>
import SLinks from '@/components/PagesPaste/SocialLinks.vue'
export default {
  components: { SLinks},
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#footer {
  
}
/* Footer */
.footer {
    padding: 5%;
    text-align: center;
}
/* .footer nav a{
  background-color: none;
}
.footer .h2-right{
    text-align: right;
} */
.footer .h2-left{
    text-align: left;
}
@media screen and (min-width: 1px) {
  .grid-container {
    display: grid;
    grid-template-areas: 
    'love'
    'nav1'
    'nav2'
    'ipumon'
    ;
    gap: 1%;
  }
}
@media screen and (min-width: 1001px) {
  .grid-container {
    display: grid;
    grid-template-areas:
      /* 'header header header header header header header header' */
      'love love ipumon ipumon'
      'nav1 nav2 nav3 nav4'
      'work work work work'
      /* 'footer footer footer footer footer footer footer footer' */
       ;
    gap: 5%;
  }
}
.love { grid-area: love; }
.ipumon { grid-area: ipumon; font-size: large; float: inline-end;}
.ipumon a { text-decoration: none; color: white;}
.ipumon nav a{ padding-left: 10px;}
.nav1 { grid-area: nav1;}
</style>
