<template>
  <div class="Dark main">
    <Navbar />
    <div class="Contant">
      <router-view/>
    </div>
    <Footer />
  </div>
</template>
<script>
import Navbar from './components/NavBar'
import Footer from './components/FootEr'

export default {
  components: {Navbar, Footer},
}
// const analytics = getAnalytics();
//   logEvent(analytics, 'goal_completion', { name: 'lever_puzzle'});
</script>
<style>
@import '@/assets/Styles/main.css';
</style>
