<template>
  <div class="topnav" id="navbar">
    <div class="navber_pad" >
      <div id="logoo" class="logo" >
        <router-link to="/" style="font-size: larger;">PMBender 
          <p style="float:right; font-size: small;">PMB</p>
          <!-- <p id="Hidden" style="font-size: medium; padding: 0;">P. Michael Bender</p> -->
        </router-link>
      </div>
      <!-- <router-link to="/#Lighting">Lighting</router-link> -->
      <router-link to="/">About</router-link>
      <router-link to="/resume">Resume</router-link>
      <router-link to="/projects">Projects</router-link>
      <!-- <div class="dropdown">
        <button class="dropbtn">Showcase 
          <i class="fa fa-caret-down"></i>
        </button>
        <div class="dropdown-content">
          <router-link to="/showcase/maths#game">Game</router-link>
          <router-link to="/#Projects">Projects</router-link>
        </div>
      </div>  -->
    </div>
    <!-- template -->
  <!-- <div class="dropdown">
    <button class="dropbtn">Dropdown 
      <i class="fa fa-caret-down"></i>
    </button>
    <div class="dropdown-content">
        <router-link to="/#Skills">Skills</router-link>
        <router-link to="/#Experience">Experience</router-link>
        <router-link to="/#Education">Education</router-link>
        <router-link to="/#Projects">Projects</router-link>
    </div>
  </div>  -->
</div>
</template>
<script>
export default {
  name: 'NavBar',
  methods: {
    myFunction() {
      var x = document.getElementById("navbar");
      if (x.className === "topnav") {
        x.className += " responsive";
      } else {
        x.className = "topnav";
      }
    }
    
  },
}
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  // if ()
  if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
    document.getElementById("navbar").style.padding = "10px 10px";
    document.getElementById("logoo").style.fontSize = "25px";
    // document.getElementById("Hidden").style.display = "none";
  } else {
    document.getElementById("navbar").style.padding = "10px 10px";
    document.getElementById("logoo").style.fontSize = "35px";
    // document.getElementById("Hidden").style.display = "none"
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navber_pad{
  padding-right: 5%;
}
#navbar{
  padding: 0 0 0 0px;
  transition: 0.4s;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
}
.topnav {
  /* overflow: hidden; */
  padding: 5%;
  width: 100;
  background-color: #1a1a1a;
}
.topnav .logo {
  font-size: 35px;
  font-weight: bold;
  transition: 0.4s;
}
.topnav a {
  float: left;
  display: block;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.active {
  background-color: #303335;
  color: white;
}

.topnav .icon {
  display: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 17px;    
  border: none;
  outline: none;
  padding: 14px 16px;
  background-color: #1a1a1a;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #1a1a1a;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.topnav a:hover, .dropdown:hover .dropbtn {
  background-color: #3a3e40;
  color: white;
}

.dropdown-content a:hover {
  background-color: #303335;
  color: black;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child), .dropdown .dropbtn {
    display: none;
  }
  .topnav .logo {
    width: 100%;
    float: right;
    display: inline;
  }
  .topnav a.icon {
    float: right;
    display: inline;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: block;
    /* right: -10;
    top: -10; */
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .topnav.responsive .dropdown {float: none;}
  .topnav.responsive .dropdown-content {position: relative;}
  .topnav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }
}
</style>
